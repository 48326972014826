<template>
  <v-app style="width: 100%;padding-top: 10px !important;background-color: #eeeeee">
    <v-snackbar
      :timeout="3000"
      :value="true"
      absolute
      top
      color="success"
      right
      text
      v-if="appear"
    >
      Emails are being sent
    </v-snackbar>
    <div style="margin: 15px 15px;">
      <v-radio-group
        v-model="row"
        row
        @change="fetchFilteredData"
        style="padding-left: -10px"
      >
        <v-radio
          label="Student"
        ></v-radio>
        <v-radio
          label="Class"
        ></v-radio>
        <v-radio
          label="Department"
        ></v-radio>
      </v-radio-group>
      <div style="float: left; width: 400px">
        <v-row>
          <v-col cols="12" style="background-color: white;border-right: #37768766 solid 2px" v-if="showSearch">
            <h2 style="float: left">Students list</h2>
            <v-btn small color="blue" style="float: right;color: white" @click="dialog = true"><i class="fa fa-plus" aria-hidden="true"
              style="color: white;padding-right:4px "></i>
              Add new
            </v-btn>
            <br><br>
            <!-- student design-->
            <div v-for="student in studentList" :key="student.id">
              <div class="info">
                <div style="display: inline-flex;padding: 6px">
                  <img style="width: 50px;height: 50px;border-radius: 50px;" :src="baseUrl + 'assets/uploads/pdf/' + student.image">
                  <div style="padding-left: 10px">
                    <span style="font-weight: 600">{{student.names}}</span><br>
                    <small>{{student.title}}</small>
                  </div>
                </div>
                <div style="float: right;padding: 17px 6px 0 0">
                  <v-btn small style="background-color: red;font-weight: 900;color: white" @click="removeStudent(student.id,student.email)">X</v-btn>
                </div>
              </div>
              <div style="height: 5px"></div>
            </div>
          </v-col>
          <v-col cols="12" style="background-color: white;border-right: #37768766 solid 2px" v-if="showDept">
            <!-- student design-->
            <div v-for="(dept,index) in departments" :key="dept.id">
              <div class="info" style="height: 60px;" v-bind:class="{'active': activeIndex === index}" :key="dept.id" @click="setClass(index)">
                <div style="display: inline-flex;padding: 2px">
                  <v-checkbox
                    v-model="checkDept[index]"
                    @click="selectDept(index,dept.id)"
                  ></v-checkbox>
                  <div style="padding-left: 10px;margin-bottom: 15px;">
                    <span style="font-weight: 600">{{dept.title}}</span><br>
                    <small>{{dept.code}}</small>
                  </div>
                </div>
              </div>
              <div style="height: 5px"></div>
            </div>
          </v-col>
          <v-col cols="12" style="background-color: white;border-right: #37768766 solid 2px" v-if="showClasses">
            <!-- student design-->
            <div v-for="(classe,index) in classes" :key="classe.id">
              <div class="info" :class="{'active': isActive === index}" @click="setClass(index)">
                <div style="display: inline-flex;padding: 6px;" >
                  <v-checkbox
                    v-model="checkClass[index]"
                    @click="selectClass(index,classe.id)"
                  ></v-checkbox>
                  <div style="padding-left: 10px">
                    <span style="font-weight: 600">{{classe.title}}</span><br>
                    <small>{{classe.level}}</small>
                  </div>
                </div>
              </div>
              <div style="height: 5px"></div>
            </div>
          </v-col>
          <!--end of left side-->
        </v-row>
      </div>
      <div style="float: right;width: calc(100% - 430px)">
        <v-row>
          <!--right side-->
          <v-col cols="12" style="text-align: center;background-color: white;">
            <v-row>
              <v-col cols="12">
                <textarea v-model="msg" id="txtArea" name="msg" style="border: 1px solid gray; border-radius: 5px;width: 100%;padding-left: 10px;height: 150px;"></textarea>
              </v-col>
            </v-row>
            <div style="float: left !important;">
              <v-btn color="blue" x-small style="color: white; background-color: #51e7ed !important;" @click="insertAtCaret('txtArea','{{name}}')" :disabled="disabled">Student name</v-btn> &nbsp;
              <v-btn color="blue" x-small style="color: white; background-color: #fdbb2d !important;" @click="insertAtCaret('txtArea','{{class}}')" :disabled="disabled">Class</v-btn>&nbsp;
              <v-btn color="blue" x-small style="color: white; background-color: #2e0ae3 !important;" @click="insertAtCaret('txtArea','{{department}}')" :disabled="disabled">Department</v-btn>
            </div>
            <div style="float: right !important;">
              <v-btn color="blue" small style="color: white; background-color: #fc4653 !important;" @click="submitEmailRecords" :disabled="disabled">Send email</v-btn>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <v-dialog v-model="dialog" width="320" :persistent="true">
      <v-card style="padding: 10px 0 20px 10px;">
        <div class="del" @click="dialog = false,std = ''">x</div>
        <v-card-title>Search student</v-card-title>
        <vx-select
          v-model="std"
          v-on:search="getStudents($event)"
          :options="students"
          v-on:input="fetchStudentsTOSendEmail"
          style="max-width: 300px;margin-bottom: 10px !important;"
        ></vx-select>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
export default {
  name: 'Communication',
  data () {
    return {
      dialog: false,
      std: '',
      filterData: [],
      items: [
        {
          text: 'Class',
          value: 'class',
        },
        {
          text: 'Department',
          value: 'dept',
        },
        {
          text: 'Student',
          value: 'student',
        },
      ],
      filter: '',
      students: [],
      classes: [],
      faculties: [],
      departments: [],
      studentList: [],
      showClasses: false,
      showDept: false,
      showSearch: false,
      checked: [],
      checkAll: false,
      showStudents: false,
      row: null,
      logger: [],
      header: '',
      studentIds: [],
      msg: '',
      studentEmail: [],
      checkDept: [],
      checkClass: [],
      classIds: [],
      deptIds: [],
      isActive: false,
      activeIndex: undefined,
      disabled: false,
      appear: false,
    }
  },
  computed: {
    baseUrl () {
      return this.axios.defaults.baseURL
    },
  },
  created () {
    this.row = 2
    this.fetchFilteredData()
  },
  methods: {
    setClass (index) {
      this.activeIndex = index
      this.isActive = !this.isActive
    },
    selectClass (index, id) {
      if (this.checkClass[index] === true) {
        this.classIds.push(id)
      } else {
        const indexOfId = this.classIds.indexOf(id)
        if (indexOfId > -1) {
          this.classIds.splice(indexOfId, 1)
        }
      }
      console.log(this.checkClass)
      console.log(this.classIds)
    },
    selectDept (index, id) {
      if (this.checkDept[index] === true) {
        this.deptIds.push(id)
      } else {
        const indexOfId = this.deptIds.indexOf(id)
        if (indexOfId > -1) {
          this.deptIds.splice(indexOfId, 1)
        }
      }
      console.log(this.checkDept)
      console.log(this.deptIds)
    },
    selectAll () {
      // this.checked[0] = this.checkAll === true
      for (let i = 0; i < this.checked.length; i++) {
        if (this.checkAll === true) {
          this.checked[i] = true
        } else {
          this.checked[i] = false
        }
      }
    },
    removeStudent (id, email) {
      this.studentList = this.studentList.filter(student => student.id !== id)
      const index = this.studentIds.indexOf(id)
      if (index > -1) {
        this.studentIds.splice(index, 1)
      }
      console.log(this.studentIds)
      const emailIndex = this.studentEmail.indexOf(email)
      if (index > -1) {
        this.studentEmail.splice(emailIndex, 1)
        console.log(this.studentEmail)
      }
    },
    async fetchFilteredData () {
      // this.progress = true
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      try {
        if (this.row === 1) {
          const res = await this.axios({
            url: 'get_classes',
            method: 'post',
            headers: {
              Authorization: 'Bearer ' + this.header,
            },
          })
          if (res.status === 200) {
            this.showClasses = true
            this.showDept = false
            this.showSearch = false
            this.classes = res.data
          }
        } else if (this.row === 2) {
          const res = await this.axios({
            url: 'get_departments',
            method: 'post',
            headers: {
              Authorization: 'Bearer ' + this.header,
            },
          })
          if (res.status === 200) {
            this.showDept = true
            this.showClasses = false
            this.showSearch = false
            this.departments = res.data
          }
        } else {
          this.showSearch = true
          this.showClasses = false
          this.showDept = false
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login-page' })
        } else if (error.response && error.response.status === 400) {
          if (this.filter === 'class') {
            this.filtered = []
          } else if (this.filter === 'dept') {
            this.filtered = []
          } else {
            this.filtered = []
          }
        } else {
          console.log(error)
        }
      }
    },
    async fetchStudentsInClass (id) {
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'get_students_class/' + id,
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.showStudents = true
          this.students = res.data.students
          console.log(this.students)
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('logged_user')
          this.$router.push({ name: 'login-page' })
        } else if (error.response && error.response.status === 400) {
          this.selected = true
          this.msg = 'No students from selected class'
          this.students = []
        } else {
          console.log(error)
        }
      }
    },
    async getStudents (event) {
      const prefix = event
      if (prefix.length === 3) {
        if (localStorage.getItem('logged_user')) {
          this.logger = JSON.parse(localStorage.getItem('logged_user'))
        }
        this.header = this.logger.accessToken
        try {
          const res = await this.axios({
            url: 'select_student',
            method: 'post',
            data: {
              prefix: prefix,
            },
            headers: {
              Authorization: 'Bearer ' + this.header,
            },
          })
          if (res.status === 200) {
            this.students = res.data
          }
        } catch (error) {
          console.log(error)
        }
      }
    },
    async fetchStudentsTOSendEmail () {
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'get_students_to_send_message',
          method: 'post',
          data: {
            id: this.std.code,
          },
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.showClasses = false
          this.showDept = false
          this.email = res.data.email
          this.studentIds.forEach(student => {
            if (student === res.data.id) {
              this.std = ''
              alert('Already selected')
              throw new Error('Something went badly wrong!')
            }
          })
          this.studentIds.push(res.data.id)
          this.studentList.push(res.data)
          this.studentEmail.push(res.data.email)
          this.std = ''
          console.log(this.studentIds)
          console.log(this.studentEmail)
        }
      } catch (error) {
        console.log(error)
      }
    },
    async submitEmailRecords () {
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      try {
        if (this.showSearch === true) {
          this.disabled = true
          const res = await this.axios({
            url: 'manipulate_email_records',
            method: 'post',
            data: {
              id: this.studentIds,
              msg: this.msg,
              email: this.studentEmail,
              check: 'students',
            },
            headers: {
              Authorization: 'Bearer ' + this.header,
            },
          })
          if (res.status === 200) {
            this.appear = true
            this.msg = ''
            this.disabled = false
            this.studentIds = []
            this.studentEmail = []
            this.studentList = []
          }
        } else if (this.showClasses === true) {
          this.disabled = true
          const res = await this.axios({
            url: 'manipulate_email_records',
            method: 'post',
            data: {
              id: this.classIds,
              msg: this.msg,
              check: 'class',
            },
            headers: {
              Authorization: 'Bearer ' + this.header,
            },
          })
          if (res.status === 200) {
            this.appear = true
            this.disabled = false
            this.msg = ''
            this.checkClass = []
            this.classIds = []
          }
        } else {
          this.disabled = true
          const res = await this.axios({
            url: 'manipulate_email_records',
            method: 'post',
            data: {
              id: this.deptIds,
              msg: this.msg,
              check: 'departments',
            },
            headers: {
              Authorization: 'Bearer ' + this.header,
            },
          })
          if (res.status === 200) {
            this.appear = true
            this.disabled = false
            this.msg = ''
            this.deptIds = []
            this.checkDept = []
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    insertAtCaret (areaId, text) {
      var txtarea = document.getElementById(areaId)
      var scrollPos = txtarea.scrollTop
      var strPos = 0
      var br = ((txtarea.selectionStart || txtarea.selectionStart == '0')
        ? 'ff' : (document.selection ? 'ie' : false))
      if (br == 'ie') {
        txtarea.focus()
        var range = document.selection.createRange()
        range.moveStart('character', -txtarea.value.length)
        strPos = range.text.length
      } else if (br == 'ff') strPos = txtarea.selectionStart

      var front = (txtarea.value).substring(0, strPos)
      var back = (txtarea.value).substring(strPos, txtarea.value.length)
      txtarea.value = front + text + back
      strPos = strPos + text.length
      if (br == 'ie') {
        txtarea.focus()
        var range = document.selection.createRange()
        range.moveStart('character', -txtarea.value.length)
        range.moveStart('character', strPos)
        range.moveEnd('character', 0)
        range.select()
      } else if (br == 'ff') {
        txtarea.selectionStart = strPos
        txtarea.selectionEnd = strPos
        txtarea.focus()
      }
      txtarea.scrollTop = scrollPos
    },
  },
}
</script>

<style scoped>
  .info {
    background-color: #f2f2f2;
    width: 100%;
    border: 1px solid #37768766 !important;
    border-radius: 3px 3px 3px 3px;
    cursor: pointer;
  }

  .student-img {
    width: 70px;
    border-radius: 50px;
  }

  .students {
    margin-left: 100px;
  }

  .v-application--wrap {
    background-color: #21242a !important;
  }
  .active {
    background-color: red !important;
  }
  .v-application .info {
    background-color: #f2f2f2 !important;
  }
  .del {
    position: absolute;
    right: 2px;
    top: -1px;
    width: 20px;
    height: 20px;
    padding-left: 7px;
    font-size: 12px;
  }
  .del:hover {
    background-color: red;
    color: white;
    cursor: pointer;
  }
</style>
